import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './TabNav.module.css';

const Tab = props => {
  const { className, id, disabled, text, selected, type, linkProps, isInbox } = props;
  // console.log('isInbox', isInbox);
  const linkClasses = classNames(isInbox ? css.inboxLink : css.link, {
    [css.selectedLink]: selected,
    [css.disabled]: disabled,
  });
  
  

  return (
    <div id={id} className={className}>
      <NamedLink
        className={linkClasses}
        style={['account', 'generic'].includes(type) ? { color: '#4a4a4a' } : {}}
        {...linkProps}
      >
        {text}
      </NamedLink>
    </div>
  );
};

Tab.defaultProps = { className: null, disabled: false, selected: false };

const { arrayOf, bool, node, object, string } = PropTypes;

Tab.propTypes = {
  id: string.isRequired,
  className: string,
  text: node.isRequired,
  disabled: bool,
  selected: bool,
  linkProps: object.isRequired,
};

const TabNav = props => {
  const { className, rootClassName, tabRootClassName, type, tabs, isInbox = false } = props;
  const classes = classNames(rootClassName || css.root, className);
  const tabClasses = tabRootClassName || css.tab;
  return (
    <nav className={classes}>
      {tabs.map((tab, index) => {
        const id = typeof tab.id === 'string' ? tab.id : `${index}`;
        return (
          <Tab key={id} id={id} type={type} className={tabClasses} {...tab} isInbox={isInbox} />
        );
      })}
    </nav>
  );
};

TabNav.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
  tabClassName: null,
};

TabNav.propTypes = {
  className: string,
  rootClassName: string,
  tabRootClassName: string,
  tabs: arrayOf(object).isRequired,
};

export default TabNav;
